<template>
  <div class="">
    <center-title title="申请对账记录"></center-title>
    <router-link to="/addapplysettlement" class="add row-center xs mb20"
      >+ 新增申请</router-link
    >
    <el-table :data="recordLists" style="width: 100%">
      <el-table-column
        align="center"
        prop="applyNickname"
        label="申请人"
        width="150"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="createTime"
        label="申请时间"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="auditStatusText"
        label="审批状态"
        width="150"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="internalEarlyAuditManager"
        label="内部初审人"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="internalEarlyAuditReject"
        label="内部初审意见"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="internalEarlyAuditTime"
        label="内部初审时间"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="internalFinalAuditManager"
        label="内部终审人"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="internalFinalAuditReject"
        label="内部终审意见"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="internalFinalAuditTime"
        label="内部终审时间"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="platformEarlyAuditManager"
        label="平台初审人"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="platformEarlyAuditReject"
        label="平台初审意见"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="platformEarlyAuditTime"
        label="平台初审时间"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="platformFinalAuditManager"
        label="平台终审人"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="platformFinalAuditReject"
        label="平台终审意见"
        width="220"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="platformFinalAuditTime"
        label="平台终审时间"
        width="220"
      >
      </el-table-column>
      <el-table-column align="center" label="操作" width="260">
        <template slot-scope="scope">
          <router-link
            :to="`/applysettlementorderlists?id=${scope.row.id}`"
            class="primary"
            >申请对账订单</router-link
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="invoice-footer row-end">
      <make-pagination
        :orderObject="recordObject"
        @resetList="resetList"
        v-if="recordLists.length"
      ></make-pagination>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import MakePagination from "@/components/MakePagination";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
    "make-pagination": MakePagination,
  },
  data() {
    return {
      page_no: 1,
      page_size: 10,
      recordLists: [],
      recordObject: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
    resetList(e) {
      this.page_no = e;
      this.getApplySettmentRecordLists();
    },
    /**
     * @description 获取申请对账记录列表
     * @return void
     */
    getApplySettmentRecordLists() {
      let { page_no, page_size } = this;
      this.$api
        .getApplySettlementRecordListsApi({
          page_no,
          page_size,
        })
        .then((res) => {
          this.recordLists = res.data.lists;
          this.recordObject = res.data;
        });
    },
  },
  created() {
    this.getApplySettmentRecordLists();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.operation {
  padding: 10px 0;

  .el-dropdown {
    font-size: 16px;
  }

  .item {
    /deep/ .el-select {
      .el-input__inner {
        background-color: transparent;
        border: none;
        font-size: 16px;
        text-align: center;
      }

      input::-webkit-input-placeholder {
        color: $-color-normal;
      }

      input::-moz-placeholder {
        color: $-color-normal;
      }

      input:-moz-placeholder {
        color: $-color-normal;
      }

      input:-ms-input-placeholder {
        color: $-color-normal;
      }

      .el-input__icon {
        color: $-color-normal;
      }

      .el-input__suffix {
        right: 15px;
      }
    }
  }
}

.order-list {
  .order-item {
    margin-bottom: 15px;

    /deep/ .el-checkbox__label {
      display: none;
    }

    .item-title {
      height: 48px;
      line-height: 48px;
    }

    .order-box {
      border: 1px solid $-color-center;
      border-top: none;
      align-items: flex-start;
    }

    .pro-list {
      .pro-item {
        padding: 20px 0;
        border-bottom: 1px solid $-color-center;
        border-right: 1px solid $-color-center;

        &:last-child {
          border-bottom: none;
        }

        .item-info {
          .item-img {
            width: 64px;
            height: 64px;
            flex-shrink: 0;
          }
        }
      }
    }

    .pro-right {
      padding-top: 44px;

      .operation-btn {
        width: 72px;
        height: 28px;
        border: 1px solid $-color-primary;
        border-radius: 4px;
        display: inline-block;
        color: $-color-primary;
        line-height: 26px;
      }
    }
  }
}

.invoice-footer {
  button {
    width: 80px;
    height: 32px;
    border: 1px solid $-color-warn;
    border-radius: 4px;
  }
}

/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 10px 0px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}

.add {
  border: $-solid-border;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
</style>
